export enum PermissionType {
  ReadGuestDetails = "ReadGuestDetails",
  RespondToReviews = "RespondToReviews",
  SendReplies = "SendReplies",
  GuestCommunicationDisabled = "GuestCommunicationDisabled",
  SeeVisitPhotoMetadata = "SeeVisitPhotoMetadata",
  ResponseTemplates = "ResponseTemplates",
  QuarantineVisits = "QuarantineVisits",
  ManageAppeals = "ManageAppeals",
  ReceiptsDisabled = "ReceiptsDisabled",
}
