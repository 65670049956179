import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { hgemColours } from "../../../themes/defaultTheme";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {},

  responseInput: {
    backgroundColor: hgemColours.White,
    "& label": {
      color: theme.palette.grey[400],
    },

    "& fieldset": {
      borderColor: theme.palette.grey[300],
    },
    "& div": {
      padding: "0 !important",
    },
    "& textarea": {
      padding: "10.5px 14px",
      marginTop: "5px",
    },
  },
  charsRemaining: {
    fontSize: "0.8em",
    color: theme.palette.grey[500],

    "&.char-overflow": {
      color: hgemColours.Red,
      fontWeight: "bold",
    },
  },
  actionContainer: {
    position: "relative",
    marginTop: "5px",
    height: "40px",
  },
  postButton: {
    position: "absolute",
    right: 0,
  },
  postButtonError: {
    position: "absolute",
    right: 0,
    color: hgemColours.Red,
  },
  responseIcon: {
    display: "block",
    float: "left",
    fontSize: "2em",
    rotate: "180deg",
    marginLeft: "50px",
    color: theme.palette.grey[200],
  },
  templateTasks: {
    marginBottom: "15px",
  },
  templateButton: {
    marginRight: "5px",
  },
  aiButton: {
    marginRight: "5px",
    backgroundColor: "white",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: hgemColours.LimeGreen,
    color: hgemColours.DarkGrey,
    "&:hover": {
      backgroundColor: hgemColours.LimeGreen,
      color: "white",
    },
  },
  AIResponsesContainer: {
    "& *": {
      boxSizing: "border-box",
    },
    position: "relative",
    "& .message-preview": {
      position: "absolute",
      bottom: "40px",
      width: "calc(100% - 20px)",
      left: "10px",
      padding: "15px",
      backgroundColor: "white",
      border: "2px solid",
      borderColor: hgemColours.LimeGreen,
      borderRadius: "10px",

      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "-11px",
        left: "var(--option)",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "11px solid transparent",
        borderRight: "11px solid transparent",
        borderTop: "11px solid",
        borderTopColor: hgemColours.LimeGreen,
      },
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "-9px",
        left: "var(--option)",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "10px solid",
        borderTopColor: "white",
      },
    },
  },
  AIResponsesRow: {
    display: "grid",
    width: "100%",
    columnGap: "5px",
    gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
    justifyContent: "start",
    marginBottom: "10px",
    boxSizing: "border-box",
  },
  AIResponse: {
    position: "relative",
    overflow: "visible",
    borderRadius: "5px",
    width: "100%",
    height: "32px",
    boxSizing: "border-box",

    "& .popover": {
      display: "none",
      position: "absolute",
      bottom: "40px",
      background: "white",
      padding: "10px",
      borderRadius: "10px",
      border: "2px solid",
      borderColor: hgemColours.LimeGreen,
      maxWidth: "300px",

      "&::before": {
        content: '""',
        background: "transparent",
        position: "absolute",
        bottom: "-11px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "11px solid transparent",
        borderRight: "11px solid transparent",
        borderTop: "11px solid",
        borderTopColor: hgemColours.LimeGreen,
      },
      "&::after": {
        content: '""',
        zIndex: 10,
        background: "transparent",
        position: "absolute",
        bottom: "-9px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "0",
        height: "0",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderTop: "10px solid white",
      },
    },
    "&:hover .popover": {
      display: "block",
    },
  },
  AIResponseButton: {
    height: "32px",
    lineHeight: "20px",
    border: "1px solid",
    borderColor: hgemColours.LimeGreen,
    borderRadius: "5px",
    padding: "6px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
    width: "100%",
    position: "relative",
    fontSize: ".875rem",
    transition: ".3s",

    "&:hover": {
      background: hgemColours.LimeGreen,
      color: "white",
    },
  },
  LoadingResponses: {
    lineHeight: "20px",
    border: "1px solid",
    borderColor: hgemColours.LimeGreen,
    borderRadius: "5px",
    padding: "6px 10px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
    position: "relative",
    fontSize: ".875rem",
    transition: ".3s",
    animation: "skeletonLoading 2s infinite",
  },
  "@keyframes skeletonLoading": {
    "0%": {
      backgroundColor: theme.palette.grey[300],
    },
    "50%": {
      backgroundColor: theme.palette.grey[100],
    },
    "100%": {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export const ReviewResponseStyles = () => {
  const theme = useTheme();
  return useStyles(theme);
};
