import React, { useEffect, useState } from "react";
import i18n from "../../../localizations/i18n";
import FilterWrapper from "./filterWrapper";
import {
  useTheme,
  makeStyles,
  FormControlLabel,
  Switch,
  Theme,
} from "@material-ui/core";
import { CaseInsensitiveSort } from "../../../utils/sortnig";
import DisabledFilter from "./disabledFilter";
import GenericMultiSelect from "../genericMultiSelect/genericMultiSelect";
import SearchTextInput from "../searchTextInput/searchTextInput";

interface IProps {
  availableQuestions: string[];
  availableQuestionSubjects: string[];
  selectedQuestions: string[];
  selectedQuestionSubjects: string[];
  onApply: (questions: string[], questionSubjects: string[]) => void;
  emptySelectionText?: string | undefined;
  disableAutoSorting?: boolean | false;
}

enum PickerType {
  Questions = 0,
  QuestionSubjects = 1,
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    margin: "10px",
    maxWidth: "450px",
  },
  actions: {
    margin: "20px 10px 10px 10px",

    "& button": {
      float: "right",
      marginLeft: "10px",
      bottom: "8px",
    },
  },
  status: {
    float: "left",
    color: theme.palette.grey[500],
  },
  switchLabel: {
    "& .MuiFormControlLabel-label": {
      color: theme.palette.grey[500],
    },
  },
}));

const QuestionPicker = (props: IProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [isOpen, setIsOpen] = useState(false);

  const [questionSubjectsAreAvailable, setQuestionSubjectsAreAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    setQuestionSubjectsAreAvailable(props.availableQuestionSubjects.length > 0);
  }, [props.availableQuestionSubjects]);

  const [initialPickerType, setInitialPickerType] = useState<PickerType>(
    PickerType.Questions
  );

  const [filteredOptions, setFilteredOptions] = useState<string[]>(
    props.availableQuestions
  );

  useEffect(() => {
    setInitialPickerType(
      questionSubjectsAreAvailable &&
        props.selectedQuestionSubjects.length >= 0 &&
        props.selectedQuestions.length === 0
        ? PickerType.QuestionSubjects
        : PickerType.Questions
    );
  }, [
    questionSubjectsAreAvailable,
    props.selectedQuestionSubjects,
    props.selectedQuestions,
  ]);

  const [selectedPickerType, setSelectedPickerType] =
    useState<PickerType>(initialPickerType);

  useEffect(() => {
    setSelectedPickerType(initialPickerType);
  }, [initialPickerType]);

  const [availableValues, setAvailableValues] = useState<string[]>([]);

  useEffect(() => {
    const values =
      selectedPickerType === PickerType.Questions
        ? props.availableQuestions
        : props.availableQuestionSubjects;
    setAvailableValues(values);
    setFilteredOptions(values);
  }, [
    selectedPickerType,
    props.availableQuestions,
    props.availableQuestionSubjects,
  ]);

  const [selectedQuestionValues, setSelectedQuestionValues] = useState<
    string[]
  >([]);

  useEffect(() => {
    const selectedValues =
      selectedPickerType === PickerType.Questions
        ? props.selectedQuestions
        : props.selectedQuestionSubjects;

    setSelectedQuestionValues(selectedValues);
  }, [
    selectedPickerType,
    props.selectedQuestions,
    props.selectedQuestionSubjects,
  ]);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const applySelection = (selected: string[]) => {
    setSelectedQuestionValues(selected);
    if (selectedPickerType === PickerType.Questions) {
      props.onApply(selected, []);
    } else {
      props.onApply([], selected);
    }

    setIsOpen(false);
  };

  const cancelSelection = () => {
    resetDefaultSelection(initialPickerType === PickerType.Questions);
    setSelectedPickerType(initialPickerType);
    setIsOpen(false);
  };

  const swicthPickerType = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    resetDefaultSelection(checked);
    setSelectedPickerType(
      checked ? PickerType.Questions : PickerType.QuestionSubjects
    );
  };

  const resetDefaultSelection = (showingFullQuestions: boolean): void => {
    const resetSelections = showingFullQuestions
      ? props.selectedQuestions
      : props.selectedQuestionSubjects;
    setSelectedQuestionValues(resetSelections);
  };

  const getSelectionHint = () => {
    if (selectedQuestionValues.length === 0) {
      return (
        props.emptySelectionText ??
        i18n.translate("QUESTIONS_PICKER_All_Selected")
      );
    } else if (selectedQuestionValues.length === 1) {
      return selectedQuestionValues[0].length > 18
        ? `${selectedQuestionValues[0].substring(0, 15)}...`
        : selectedQuestionValues[0];
    } else {
      return `${selectedQuestionValues.length} ${i18n.translate(
        "QUESTIONS_PICKER_Selected"
      )}`;
    }
  };

  const buildTooltipContent = (): string => {
    if (selectedQuestionValues && selectedQuestionValues.length > 0) {
      const tooltipText = `${selectedQuestionValues.length} ${i18n.translate(
        "QUESTIONS_PICKER_Selected_Tooltip"
      )} `;
      let questions = (
        props.disableAutoSorting
          ? selectedQuestionValues
          : selectedQuestionValues.sort(CaseInsensitiveSort)
      ).join(", ");

      if (questions.length > 150) {
        questions = questions.substring(0, 150) + "...";
      }

      return tooltipText + questions;
    } else {
      return "";
    }
  };

  const clearSearch = () => {
    setFilteredOptions(availableValues);
  };

  const applySearch = (value: string) => {
    setFilteredOptions(
      availableValues.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <>
      {availableValues.length > 0 && (
        <FilterWrapper
          label={i18n.translate("QUESTIONS_PICKER_Questions")}
          selectionHint={getSelectionHint()}
          isOpen={isOpen}
          toggleOpenState={handleToggleOpen}
          tooltip={buildTooltipContent()}
          isDisabled={availableValues.length === 0}
        >
          <div className={classes.container}>
            {questionSubjectsAreAvailable && (
              <div>
                <FormControlLabel
                  className={classes.switchLabel}
                  control={
                    <Switch
                      checked={selectedPickerType === PickerType.Questions}
                      onChange={swicthPickerType}
                      name="question-picker-type-swicth"
                      color="primary"
                    />
                  }
                  label={i18n.translate("QUESTIONS_PICKER_Show_Full_Text")}
                />
              </div>
            )}
            <SearchTextInput
              label=""
              searchPlaceholder={i18n.translate(
                "QUESTIONS_PICKER_Search_Placeholder"
              )}
              onClearSearch={clearSearch}
              onSearch={applySearch}
            />

            <GenericMultiSelect
              useSelectAllOption={false}
              multiSelect={true}
              options={filteredOptions}
              selectedOptions={selectedQuestionValues}
              applySelectionCallback={applySelection}
              cancelSelectionCallback={cancelSelection}
              applyLocalisationToOptions={false}
            />
          </div>
        </FilterWrapper>
      )}
      {availableValues.length === 0 && (
        <DisabledFilter label={i18n.translate("QUESTIONS_PICKER_Questions")} />
      )}
    </>
  );
};

export default QuestionPicker;
