import { AuthServiceInstance } from "../authentication/authService";
import { PermissionType } from "./permissionsUtils";

const IsAuthorised = async (permission: PermissionType): Promise<boolean> => {
  let isAuthorised = false;

  switch (permission) {
    case PermissionType.ReadGuestDetails:
      isAuthorised = await guestCommunicationTabIsDisabled();
      break;
    case PermissionType.RespondToReviews:
      isAuthorised = await IsAuthorisedForReviewResponses();
      break;
    case PermissionType.SendReplies:
      isAuthorised = await IsAuthorisedToSendReplies();
      break;
    case PermissionType.GuestCommunicationDisabled:
      isAuthorised = await guestCommunicationTabIsDisabled();
      break;
    case PermissionType.SeeVisitPhotoMetadata:
      isAuthorised = await IsAuthorisedToSeeVisitPhotoMetadata();
      break;
    case PermissionType.QuarantineVisits:
      isAuthorised = await isAuthorisedToQuarantineVisits();
      break;
    case PermissionType.ManageAppeals:
      isAuthorised = await isAuthorisedToManageAppeals();
      break;
    case PermissionType.ReceiptsDisabled:
      isAuthorised = await visitReceiptsDisabled();
      break;
    case PermissionType.ResponseTemplates:
      isAuthorised = await isAuthorisedToUseResponseTemplates();
  }

  return isAuthorised;
};

export default IsAuthorised;

const isAuthorisedToUseResponseTemplates = async (): Promise<boolean> => {
  const clientId = await AuthServiceInstance.getClientId();

  const clientIds = process.env.REACT_APP_EMAIL_RESPONSE_TEMPLATES_DENIED;

  const clientsIdsArray = getIdNumbers(clientIds);

  const isDenied = clientsIdsArray.includes(clientId);

  let isPermitted = true;

  if (isDenied) {
    isPermitted = false;
  }
  return isPermitted;
};

const IsAuthorisedForReviewResponses = async (): Promise<boolean> => {
  const clientId = await AuthServiceInstance.getClientId();
  const managerId = await AuthServiceInstance.getUserId();

  const deniedClientsConfig =
    process.env.REACT_APP_REVIEW_RESPONSE_DENIED_CLIENTS;

  const permittedManagers =
    process.env.REACT_APP_GUEST_COMMUNICATIONS_PERMITTED_MANAGER_IDS;

  const deniedClients = getIdNumbers(deniedClientsConfig);
  const permittedManagersArray = getIdNumbers(permittedManagers);
  const managerHasPermission = permittedManagersArray.includes(managerId);
  const clientHasPermission = () => {
    if (deniedClients.find((x) => x === clientId)) {
      return false;
    }
    return true;
  };
  if (managerHasPermission && clientHasPermission() === false) {
    return true;
  }
  if (clientHasPermission()) {
    return true;
  }
  return false;
};

const guestCommunicationTabIsDisabled = async () => {
  const managerId = await AuthServiceInstance.getUserId();
  const clientId = await AuthServiceInstance.getClientId();

  const exemptedManagers =
    process.env
      .REACT_APP_GUEST_COMMUNICATIONS_DISABLED_CLIENT_MANAGER_EXEMPTIONS;

  const disabledClients = process.env.REACT_APP_GUEST_DETAILS_DENIED_CLIENTS;

  const managerIds = getIdNumbers(exemptedManagers);
  const clientIds = getIdNumbers(disabledClients);

  const clientHasPermissionSettings = clientIds.includes(clientId);
  const managerHasExempetion = managerIds.includes(managerId);

  if (clientHasPermissionSettings && !managerHasExempetion) {
    return false;
  }

  return true;
};

const IsAuthorisedToSendReplies = async () => {
  const managerId = await AuthServiceInstance.getUserId();
  const clientId = await AuthServiceInstance.getClientId();

  const permittedManagers =
    process.env.REACT_APP_GUEST_COMMUNICATIONS_PERMITTED_MANAGER_IDS;

  const permittedManagersArray = getIdNumbers(permittedManagers);

  const permittedClients =
    process.env.REACT_APP_GUEST_COMMUNICATIONS_MANAGER_PERMISSIONS_CLIENTS;
  const clientsArray = getIdNumbers(permittedClients);

  const clientPermitted = clientsArray?.includes(clientId);

  const managerPermitted = permittedManagersArray.includes(managerId);

  if (clientPermitted && managerPermitted) {
    return true;
  }

  if (!clientPermitted) {
    return true;
  }

  return false;
};

const IsAuthorisedToSeeVisitPhotoMetadata = async () => {
  const clientId = await AuthServiceInstance.getClientId();

  const permittedClients = process.env.REACT_APP_VISIT_PHOTO_METADATA_CLIENTS;

  const clientsArray = getIdNumbers(permittedClients);

  return clientsArray?.includes(clientId);
};

const getIdNumbers = (string: string | undefined): number[] => {
  return string
    ? string.split(",").map((id: string) => Number.parseInt(id))
    : [];
};

const isAuthorisedToQuarantineVisits = async () => {
  const managerId = await AuthServiceInstance.getUserId();
  const permittedManagers =
    process.env.REACT_APP_QUARANTINE_PERMITTED_MANAGER_IDS;

  const permittedManagersArray = getIdNumbers(permittedManagers);

  return permittedManagersArray.includes(managerId);
};

const isAuthorisedToManageAppeals = async () => {
  const managerId = await AuthServiceInstance.getUserId();
  const permittedManagers = process.env.REACT_APP_APPEAL_MGMT_MANAGER_IDS;

  const permittedManagersArray = getIdNumbers(permittedManagers);

  return permittedManagersArray.includes(managerId);
};

const visitReceiptsDisabled = async () => {
  const clientId = await AuthServiceInstance.getClientId();

  const disabledClients = process.env.REACT_APP_RECEIPTS_DISABLED_CLIENTS;

  const clientIds = getIdNumbers(disabledClients);

  const clientHasReceiptsDisabled = clientIds.includes(clientId);

  return clientHasReceiptsDisabled;
};
