import {
  Fade,
  FormControlLabel,
  Hidden,
  Icon,
  Switch,
  Zoom,
} from "@material-ui/core";
import { ApproverSelect, AppealQuestionPicker, AppealStatus } from "./";
import { BrandSelectionFormStyles } from "../../userToolbar/styles/brandSelectionFormStyles";
import React, { useState, useEffect } from "react";
import { i18n } from "../../../localizations";
import { useAppealReferenceQuestions } from "../../../state/hooks/appeals/useReferenceQuestions";
import { useApprovalManagers } from "../../../state/hooks/appeals/useApprovalManagers";
import { ICaseDetails, IManager } from "../../../state/types/TaskCentreCases";
import CommonCommentField from "../../_common/comments/components/commonCommentField";
import {
  postAppealUploadAttachmentApi,
  postRaiseAppealApi,
} from "../../../state/api/Appeals-API";
import { AppealsStyles } from "../styles";
import AppealsButton from "./appealsButton";
import { useIsAdmin } from "../../../state/hooks/clients/useIsAdmin";
import { CommentStyles } from "../../taskCentreEvents/styles/commentStyles";

type Props = {
  clientId: number;
  visitId: number | undefined;
  userId: number;
  isApprovalManager: boolean;
  userName?: string;
  managers: IManager[];
  setIsPendingAction: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentAppeal: React.Dispatch<
    React.SetStateAction<ICaseDetails | undefined>
  >;
  pendingAttachments: File[];
};
const NewAppeal = (props: Props) => {
  const {
    clientId,
    visitId,
    userId,
    setIsPendingAction,
    setCurrentAppeal,
    isApprovalManager,
    pendingAttachments,
  } = props;

  const appealsClasses = AppealsStyles();
  const commentClasses = CommentStyles();

  const isAdmin = useIsAdmin();

  const [wholeReport, setWholeReport] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [bonusAppealOnly, setBonusAppealOnly] = useState<boolean>(false);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const [approverIsInvalid, setApproverIsInvalid] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [selectedQuestionsIsInvalid, setSelectedQuestionsIsInvalid] =
    useState<boolean>(false);
  const [selectedManager, setSelectedManager] = useState<
    IManager | undefined
  >();
  const [comments, setComments] = useState<string | undefined>();

  useEffect(() => {
    isLoading ? setIsPendingAction(true) : setIsPendingAction(false);
  }, [isLoading]);
  const appealReferenceQuestions = useAppealReferenceQuestions({
    clientId: clientId,
    visitId: visitId,
  });

  const approvalManagers = useApprovalManagers({
    clientId: clientId,
    visitId: visitId,
  });

  useEffect(() => {
    if (isApprovalManager) {
      setSelectedManager(undefined);
    }
  }, [approvalManagers, userId]);

  const selectedQuestionsIsValid = (): void => {
    if (selectedQuestionIds.length === 0 && !wholeReport) {
      setSelectedQuestionsIsInvalid(true);
    }
    setSelectedQuestionsIsInvalid(false);
  };
  const raiseAppeal = async () => {
    setIsPendingAction(true);
    await postRaiseAppealApi(
      clientId,
      props.visitId,
      "Appeal",
      comments,
      userId,
      selectedManager?.id,
      wholeReport || bonusAppealOnly ? [] : selectedQuestionIds,
      bonusAppealOnly
    ).then(async (response) => {
      const newCase = await pendingAttachments
        .reverse()
        .reduce(async (previousCasePromise, attachment) => {
          const previousCase = await previousCasePromise;

          const newRespnse = await postAppealUploadAttachmentApi(
            clientId,
            previousCase.id,
            isAdmin ? undefined : userId,
            attachment,
            isAdmin
          );

          return newRespnse.data;
        }, Promise.resolve(response.data));

      resetNewAppealValidation();
      setCurrentAppeal(newCase);
      setIsPendingAction(false);
    });
  };

  const validate = () => {
    return new Promise<void>((resolve, reject) => {
      if (
        !isApprovalManager &&
        !selectedManager &&
        selectedQuestionIds.length === 0 &&
        !wholeReport &&
        !bonusAppealOnly
      ) {
        setSelectedQuestionsIsInvalid(true);
        setApproverIsInvalid(true);

        reject();
        return;
      }
      if (!isApprovalManager && !selectedManager) {
        setApproverIsInvalid(true);

        reject();
        return;
      }
      if (
        selectedQuestionIds.length === 0 &&
        !wholeReport &&
        !bonusAppealOnly
      ) {
        setSelectedQuestionsIsInvalid(true);

        reject();
        return;
      }

      resolve();
    });
  };

  const selectedManagerIsValid = (): void => {
    if (!isApprovalManager && !selectedManager) {
      setApproverIsInvalid(true);
    }
    setApproverIsInvalid(false);
  };

  const resetNewAppealValidation = (): void => {
    setSelectedQuestionsIsInvalid(false);
    setApproverIsInvalid(false);
  };

  const commonClasses = BrandSelectionFormStyles();

  return (
    <>
      <div className={`${appealsClasses.flexRow} ${appealsClasses.topRow}`}>
        <span className={`${appealsClasses.appealStatus}`}>
          <AppealStatus />
        </span>
        {!isApprovalManager && (
          <>
            <span className={`${appealsClasses.flexItem}`}>
              <ApproverSelect
                managers={approvalManagers}
                selectedManager={selectedManager}
                selectChange={setSelectedManager}
                isInvalid={approverIsInvalid}
                validate={selectedManagerIsValid}
              />
            </span>
          </>
        )}
        <Hidden implementation="js" xsDown>
          <span className={appealsClasses.newAppealButtonsBar}>
            <AppealsButton
              name={i18n.translate("COMMON_BUTTON_TEXT_submit")}
              isPending={isLoading}
              promise={() => validate().then(() => raiseAppeal())}
            />
          </span>
        </Hidden>
      </div>
      <div className={`${appealsClasses.flexRow}`}>
        <span className={`${appealsClasses.wholeReportSwitch}`}>
          <FormControlLabel
            label={i18n.translate("APPEALS_WHOLE_REPORT_LABEL")}
            control={
              <Switch
                size="medium"
                className={commonClasses.checkbox}
                checked={wholeReport}
                onChange={(event) => {
                  setWholeReport(event.target.checked);

                  if (event.target.checked) {
                    setBonusAppealOnly(false);
                  }
                }}
              />
            }
          />
        </span>
        <span>
          <FormControlLabel
            label={i18n.translate("APPEALS_QUESTIONS_BONUS_APPEAL_ONLY")}
            control={
              <Switch
                size="medium"
                className={commonClasses.checkbox}
                checked={bonusAppealOnly}
                onChange={(event) => {
                  setBonusAppealOnly(event.target.checked);

                  if (event.target.checked) {
                    setWholeReport(false);
                  }
                }}
              />
            }
          />
        </span>
      </div>
      <Fade in={!wholeReport && !bonusAppealOnly}>
        <div>
          <AppealQuestionPicker
            appealReferenceQuestions={appealReferenceQuestions}
            selectedQuestions={selectedQuestions}
            setSelectedQuestionIds={setSelectedQuestionIds}
            setSelectedQuestions={setSelectedQuestions}
            isInvalid={selectedQuestionsIsInvalid}
            validate={selectedQuestionsIsValid}
          />
        </div>
      </Fade>
      {showComments && (
        <div style={{ marginTop: "3%" }}>
          <Zoom in={showComments}>
            <div className={`${commentClasses.newAppealContainer}`}>
              <span className={commentClasses.newAppealText}>{comments}</span>
              <Icon
                className={`fas fa-pen-to-square ${commentClasses.editIcon}`}
                onClick={() => setShowComments(false)}
              />
            </div>
          </Zoom>
        </div>
      )}

      {!showComments && (
        <Zoom in={!showComments}>
          <div style={{ marginTop: "3%" }}>
            <CommonCommentField
              fieldNotification={undefined}
              onChange={(commentText) => setComments(commentText)}
              onSubmit={() => setShowComments(true)}
              editableText={comments}
            />
          </div>
        </Zoom>
      )}
      <Hidden implementation="css" xsUp>
        <span className={appealsClasses.newAppealButtonsBar}>
          <AppealsButton
            name={i18n.translate("COMMON_BUTTON_TEXT_submit")}
            isPending={isLoading}
            promise={() => validate().then(() => raiseAppeal())}
          />
        </span>
      </Hidden>
    </>
  );
};

export default NewAppeal;
