import axios, { AxiosResponse } from "axios";
import { ICaseDetails, IManager } from "../types/TaskCentreCases";
import { AttachmentsAddedCommand } from "../types/TaskCentreCommands";
import {
  IAppealReferenceQuestion,
  IAppealReviewDecision,
  IAppealsAcess,
  IAppealsFeatureActive,
} from "../types/Appeals";

export const getAppealsFeatureActiveApi = (
  clientId: number
): Promise<AxiosResponse<IAppealsFeatureActive>> => {
  return axios.get<IAppealsFeatureActive>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/FeatureActive`
  );
};

export const getCanAccessAppealApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<IAppealsAcess>> => {
  return axios.get<IAppealsAcess>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Visit/${visitId}/CanAccessAppeal`
  );
};

export const getReferenceQuestionsApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<IAppealReferenceQuestion[]>> => {
  return axios.get<IAppealReferenceQuestion[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Visit/${visitId}/GetReferenceQuestions`
  );
};

export const getApprovalManagersApi = (
  clientId: number,
  visitId: number
): Promise<AxiosResponse<IManager[]>> => {
  return axios.get<IManager[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Visit/${visitId}/GetApprovalManagers`
  );
};

export const postRaiseAppealApi = (
  clientId: number,
  visitId: number | undefined,
  title: string,
  comment: string | undefined,
  authorId: number | undefined,
  approvalManagerId: number | undefined,
  questionIds: number[],
  bonusAppealOnly: boolean
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/CreateAppeal`,
    {
      visitId,
      title,
      comment,
      authorId,
      approvalManagerId,
      questionIds,
      bonusAppealOnly,
    }
  );
};

export const postGetAppealApi = (
  clientId: number,
  visitId: number,
  hasAppealAdminAccess = false
): Promise<ICaseDetails | undefined> => {
  return axios
    .post<ICaseDetails[]>(
      `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/GetAppeals`,
      {
        visitId,
        hasAppealAdminAccess,
      }
    )
    .then((x) => x.data[0]);
};

export const postAppealApprovedForReviewApi = (
  clientId: number,
  caseId: number,
  comment: string,
  authorId: number
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      authorId,
      commandType: "ApproveAppealForReview",
    }
  );
};

export const postAppealCancelledApi = (
  clientId: number,
  caseId: number,
  comment: string,
  authorId?: number
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      authorId,
      commandType: "CancelAppeal",
    }
  );
};

export const postAppealGrantedApi = (
  clientId: number,
  caseId: number,
  comment: string,
  sendNotification: boolean
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      commandType: "GrantAppeal",
      sendNotification,
    }
  );
};

export const postAppealUnderReviewApi = (
  clientId: number,
  caseId: number,
  comment: string
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      commandType: "ReviewAppeal",
    }
  );
};

export const postAppealRejectedApi = (
  clientId: number,
  caseId: number,
  comment: string
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      commandType: "RejectAppeal",
    }
  );
};

export const postAppealAddCommentApi = (
  clientId: number,
  caseId: number,
  comment: string,
  isInternal: boolean,
  hasAppealAdminAccess: boolean,
  authorId?: number
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      authorId,
      isInternal,
      commandType: "AddComment",
      hasAppealAdminAccess,
    }
  );
};

export const getAppealReviewDecisionsApi = (
  clientId: number
): Promise<AxiosResponse<IAppealReviewDecision[]>> => {
  return axios.get<IAppealReviewDecision[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/AppealReviewDecisions`
  );
};

export const postAppealChangeAppealReviewDecisionApi = (
  clientId: number,
  caseId: number,
  comment: string,
  appealReviewDecisionId: number | undefined
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/UpdateAppeal/${caseId}`,
    {
      comment,
      appealReviewDecisionId,
      commandType: "ChangeAppealReviewDecision",
    }
  );
};

export const postAppealUploadAttachmentApi = (
  clientId: number,
  caseId: number,
  authorId: number | undefined,
  file: File,
  hasAppealAdminAccess: boolean
): Promise<AxiosResponse<ICaseDetails>> => {
  const formData = new FormData();

  formData.append(
    "text",
    JSON.stringify({
      commandType: AttachmentsAddedCommand.type,
      authorId,
    })
  );

  formData.append("", file);

  const headers = {
    "X-HasAppealAdminAccess": hasAppealAdminAccess,
  };

  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Appeal/${caseId}/UploadAttachment`,
    formData,
    { headers }
  );
};

export const deleteAppealAttachmentApi = (
  clientId: number,
  visitId: number,
  caseId: number,
  authorId: number | undefined,
  caseEventAttachmentId: number,
  hasAppealAdminAccess: boolean
): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.delete<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Appeal/${caseId}/DeleteAttachment`,
    {
      data: {
        authorId,
        visitId,
        caseEventAttachmentId,
        hasAppealAdminAccess,
      },
    }
  );
};

export const getAppealAttachmentApi = (
  clientId: number,
  visitId: number,
  caseId: number,
  caseEventAttachmentId: number,
  filepath: string
): Promise<AxiosResponse<Blob>> => {
  return axios.get<Blob>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${clientId}/Visit/${visitId}/Appeal/${caseId}/Attachment/${caseEventAttachmentId}/${filepath}`,
    { responseType: "blob" }
  );
};

export const getAttachmentsDisallowedFileExtensionsApi = (): Promise<
  AxiosResponse<string[]>
> => {
  return axios.get<string[]>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/AttachmentsDisallowedFileExtensions`
  );
};

export const postAppealEditApi = (params: {
  clientId: number;
  caseId: number;
  bonusAppealOnly: boolean | undefined;
  reviewerId: number | undefined;
  deleteQuestionIds: number[] | undefined;
  addQuestionIds: number[] | undefined;
}): Promise<AxiosResponse<ICaseDetails>> => {
  return axios.post<ICaseDetails>(
    `${process.env.REACT_APP_CASE_MANAGEMENT_API_BASEURL}Appeals/Client/${params.clientId}/UpdateAppeal/${params.caseId}`,
    {
      bonusAppealOnly: params.bonusAppealOnly,
      reviewerId: params.reviewerId,
      deleteQuestionIds: params.deleteQuestionIds,
      addQuestionIds: params.addQuestionIds,
      commandType: "EditAppeal",
    }
  );
};
